<!--  -->
<template>
  <div class="meeting-room-manager">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '会议室管理',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->

    <div class="list-metting">
      <div v-if="list && list.length > 0">
        <div class="list-item" v-for="(item, i) in list" :key="i">
          <div class="layer-name-btn">
            <p class="name">{{ item.mrName }}</p>
            <span class="txt" @click="editMeetingRoom(item)">编辑</span>
          </div>
          <div class="layer-equipments">
            {{ item.equipments | filterEquipments }}
          </div>
          <div class="num-area">
            <p class="area">{{ item.address }}</p>
            /
            <p class="num">
              <van-icon name="friends-o" class="icon-o" />{{
                item.peopleNumber
              }}
            </p>
          </div>
        </div>
      </div>
      <van-empty v-else description="未获取到会议室" />
    </div>
    <div class="btn-opt">
      <p class="add-point" @click="showAddress = true">新增地点</p>
      <p class="add-metting-room" @click="addMettingRoom">新增会议室</p>
    </div>
    <!-- 添加地点 -->
    <van-popup v-model="showAddress" round>
      <div class="address-popup">
        <div class="title">新增会议室地点</div>

        <van-field
          class="input-text"
          v-model.trim="address"
          rows="1"
          label=""
          maxlength="20"
          type="textarea"
          placeholder="请输入会议室地点"
        />

        <div class="opt-text">
          <span @click="cancelAddress">取消</span>
          <span @click="sureAddress" class="sure">确定</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  addMeetingAddress,
  meetingRoomList,
  uploadImg,
  addMeeting,
  addressList,
  delMeeting,
  editMeeting,
  noAdderessMeetingRoomList,
} from "@/api/smartMeetingRoom/index.js";
import { Toast } from "vant";
import TopWrapper from "@/components/topWrapper/index.vue";

export default {
  data() {
    return {
      list: [],
      showAddress: false,
      address: "",
    };
  },
  filters: {
    filterEquipments(val) {
      if (!val) return "-";
      if (val.indexOf(",") > 0) return val.replaceAll(",", "/");
      return val;
    },
  },

  components: { TopWrapper },

  computed: {},

  mounted() {
    this.getList();
  },

  methods: {
    //获取会议室列表
    getList() {
      noAdderessMeetingRoomList().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.list = data;
          }
        }
      });
    },
    //取消添加
    cancelAddress() {
      this.address = "";
      this.showAddress = false;
    },
    //添加会议室
    sureAddress() {
      let param = { address: this.address };
      addMeetingAddress(param).then((res) => {
        this.loading = false;
        if (res) {
          let { msg, code } = res;
          if (code == 200) {
            Toast(msg);
            this.cancelAddress();
            this.getList();
          }
        }
      });
    },
    //添加会议室
    addMettingRoom() {
      this.$router.push("/addMeetingRoom");
    },
    //编辑会议室
    editMeetingRoom(row) {
      this.$router.push({
        path: "/addMeetingRoom",
        query: {
          code: row.mrCode,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.meeting-room-manager {
  width: 100%;
  height: auto;
  min-height: 100%;
  background: #fafafa;
  position: relative;
  padding: 0.3rem;
  .list-metting {
    min-height: 11.3rem;
    .list-item {
      height: 1.84rem;
      border-radius: 0.2rem;
      background-color: rgba(255, 255, 255, 1);
      margin-bottom: 0.2rem;
      width: 6.9rem;
      margin-top: 0.2rem;
      border-radius: 0.2rem;
      padding: 0.2rem;
      .layer-name-btn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 0.42rem;
        align-items: center;
        .name {
          flex: 1;
          height: 0.42rem;
          display: block;
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 0.3rem;
          white-space: nowrap;
          line-height: 0.42rem;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .txt {
          width: 0.52rem;
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 148, 238, 1);
          font-size: 0.26rem;
          white-space: nowrap;
          text-align: left;
        }
      }
      .layer-equipments {
        text-overflow: ellipsis;
        overflow: hidden;
        height: 0.37rem;
        line-height: 0.37rem;
        color: rgba(153, 153, 153, 1);
        font-size: 0.26rem;
        margin-top: 0.12rem;
      }
      .num-area {
        display: flex;
        flex-direction: row;
        justify-content: left;
        height: 0.37rem;
        align-items: center;
        color: rgba(153, 153, 153, 1);
        font-size: 0.26rem;
        margin-top: 0.12rem;
        .area {
          max-width: 7rem;
          white-space: nowrap;
          line-height: 0.37rem;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .num {
          white-space: nowrap;
          line-height: 0.37rem;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          .icon-o {
            margin-left: 0.12rem;
            margin-right: 0.05rem;
          }
        }
      }
    }
  }
  .btn-opt {
    width: 100%;
    height: 0.8rem;

    display: flex;
    justify-content: center;
    .add-point {
      height: 0.8rem;
      width: 2.4rem;
      background: rgba(0, 148, 238, 0.1);
      border-radius: 0.4rem;
      font-weight: 400;
      color: #0094ee;
      font-size: 0.26rem;
      text-align: center;
      line-height: 0.8rem;
    }
    .add-metting-room {
      height: 0.8rem;
      width: 2.4rem;
      margin-left: 0.4rem;
      background: #0094ee;
      border-radius: 0.4rem;
      font-weight: 400;
      color: #fff;
      font-size: 0.26rem;
      text-align: center;
      line-height: 0.8rem;
    }
  }
  .address-popup {
    height: 3.94rem;
    background: #ffffff;
    border-radius: 0.16rem;
    width: 5.7rem;
    padding: 0.2rem;
    .title {
      height: 0.45rem;
      font-size: 0.32rem;
      font-weight: 500;
      color: #333333;
      line-height: 0.45rem;
      text-align: center;
    }
    .input-text {
      margin-top: 0.24rem;
      width: 5.3rem;
      height: 2.09rem;
      background: #f4f9fb;
      border-radius: 0.16rem;
    }
    .opt-text {
      margin-top: 0.24rem;
      display: flex;
      width: 100%;
      flex-direction: row;

      span {
        width: 50%;
        height: 0.45rem;
        font-size: 0.32rem;
        font-weight: 400;
        color: #0094ee;
        line-height: 0.45rem;
        text-align: center;
      }
      .sure {
        border-left: 0.01rem solid #f0f0f0;
      }
    }
  }
}
</style>
